.previewGift {
  width: 911px;
  height: 597px;
  .previewGift-title {
    color: #1f366c;
    font-size: 32px;
    text-align: center;
    font-weight: 500;
  }
  .previewGift-SubImage > #mainSvg {
    bottom: 150px;
    left: 68px;
    width: auto;
    position: relative;
    height: 80px !important;
    transform: rotate(3deg);
  }
  .previewGift-SubImage,
  .previewGift-SubImage-tin {
    position: absolute;
  }
  .previewGift-SubImage-tin > #mainSvg {
    left: 91px;
    /* width: 340px; */
    position: relative;
    height: 222px !important;
    transform: rotateZ(1deg) rotateX(16deg) rotateY(-23deg);
    top: 14px;
    padding: 10px 14px 18px 0px;
  }
  .previewGift-image{
    margin-top: 10px !important;
  }
  .previewGift-image-one {
    display: flex;
    align-items: center;
    height: 66%;
  }
}
.TinImage {
  // height: 450px;
  height: 260px !important;
  width: 341px !important;
}
