.ant-modal {
  // width: 1415px !important;
  transform-origin: 392.5px 417px;
  display: flex;
  justify-content: center;
}
.image-model {
  .ant-modal-content {
    width: 640px;
    height: 426px;
    border-radius: 10px;
    // max-width: 1026px;
    top: 115px;
    left: 30px;
  }
  .ant-modal {
    // width: 1100px !important;
    //top: 73px;
  }
  .ant-modal-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
  .model-logo {
    display: flex;
    justify-content: end;
    padding: 20px;
  }
  .image-model-title {
    color: #003366;
    font-size: 32px;
    font-weight: 500;
    width: 578px;
    text-align: center;
    // height: 136px;
    text-transform: uppercase;
    line-height: 1.094;

  }
}
@media only screen and (min-width: 768px) and(max-width: 1024px) {
  .image-model .ant-modal-content {
    height: 540px;
    width: 90vw;
    border-radius: 10px;
    max-width: 1026px;
    top: 120px;
    left: 0px;
  }
  .image-model .image-model-title {
    color: #1f366c;
    font-size: 48px;
    font-weight: 500;
    width: 750px;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: -0.5px;
    margin-top: 28px;
  }
}
#react-qrcode-logo{
  width: 200px !important;
  height: 200px !important;
  margin-bottom: 110px !important;
}

