.finish {
  width: 465px;
  height: auto;
  .finish-main {
    padding: 20px;
    .finish-title {
      color: #1f366c;
      font-size: 32px;
      line-height: 1.313;
      text-transform: uppercase;
      font-weight: 500;
    }
    .finish-center-right {
      .finish-center-right-text {
        color: #333333;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        width: 100%;
      }
      .finish-center-right-text-two {
        // width: 426px;
        height: 100px;
        color: #333333;
        font-size: 36px;
        text-align: center;
      }
    }
    .previewGift-SubImage > #mainSvg {
      left: 32px;
      width: auto;
      position: relative;
      height: 90px !important;
      transform: rotate(3deg);
      top: 207px;
    }
    .previewGift-SubImage,
    .previewGift-SubImage-tin {
      position: absolute;
    }
    .previewGift-SubImage-tin > #mainSvg {
      left: 19px;
      width: 370px;
      position: relative;
      height: 237px !important;
      transform: rotateZ(1deg) rotateX(16deg) rotateY(-23deg);
      top: 33px;
      padding: 10px 0px 18px 0px;
    }
  }
}
