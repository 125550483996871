.box {
  // padding: 30px 24px 15px 24px;
  // border: 13px solid RGBA(173, 152, 99, 0.5);
  border: 15px solid RGBA(145, 153, 149);
  border-radius: 20px;
  position: relative;
  background-color: RGB(255, 255, 255, 0.85);
  box-shadow: 0px 2px 5px 5px RGBA(0, 0, 0, 0.04);
  top: -30px;

  .logo {
    position: absolute;
    height: 120px;
    top: -1%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Start-logo {
    position: absolute;
    height: 120px;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Offline-logo {
    position: absolute !important;
    height: 120px !important;
    top: -1% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .sideImageBox {
    position: absolute;
    height: 400px;
    top: 0px;
  }
  .left-Image {
    left: -125px;
    top: 68px;
  }
  .right-image {
    top: -28px;
    right: -131px;
  }
}

.personalize-box {
  top: 20px;
  border: 15px solid RGBA(173, 152, 99, 0.5);
  border-radius: 20px;
  position: relative;
  background-color: RGB(255, 255, 255, 0.85);
  box-shadow: 0px 2px 5px 5px RGBA(0, 0, 0, 0.04);
  .logo {
    position: absolute;
    height: 120px;
    top: -1%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.basic-info-box {
  border: 15px solid RGBA(173, 152, 99, 0.5);
  top: -30px;
  border-radius: 20px;
  position: relative;
  background-color: RGB(255, 255, 255, 0.85);
  box-shadow: 0px 2px 5px 5px RGBA(0, 0, 0, 0.04);
  .logo {
    position: absolute;
    height: 120px;
    top: -1%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: 768px) and(max-width: 1024px) {
  .right-image {
    top: -20px !important;
    right: -9px !important;
  }
  .right-image img {
    width: 120px !important;
    height: 365px !important;
  }
  .left-Image img {
    width: 200px !important;
    height: 130px !important;
  }
  .left-Image {
    left: -18px !important;
    top: 120px !important;
  }
}
