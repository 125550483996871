.selectImage {
  width: 911px;
  height: 573px;
  .selectImage-top {
    display: flex;
    justify-content: center;
    margin: 15px 0px;
  }

  .selectImage-top-text {
    font-weight: 500;
    text-align: center;
    color: #1f366c;
    font-size: 32px;
    line-height: 1.313;
    margin-top: 30px;
    // width: 1054px;
  }

  .selectImage-center {
    .selectImage-center-Bag {
      margin-bottom: 100px;
      .first-img {
        position: absolute;
        height: 80px;
        width: 86px;
        /* bottom: 299px; */
        transform: rotate(3deg);
        /* right: 111px; */
        top: 194px;
        left: 75px;
      }
      .second-img {
        transform: rotateZ(1deg) rotateX(16deg) rotateY(-23deg);
        position: absolute;
        width: 170px;
        height: 150px;
        bottom: 21px;
        right: 45px;
      }
    }
    .selectImage-center-Tin {
      height: 325px;
      position: relative;
      align-items: flex-start;
      .first-img {
        position: absolute;
        height: 80px;
        width: 86px;
        /* bottom: 299px; */
        transform: rotate(3deg);
        /* right: 111px; */
        top: 194px;
        left: 75px;
      }
      .second-img {
        transform: rotateZ(1deg) rotateX(16deg) rotateY(-23deg);
        position: absolute;
        width: 170px;
        height: 150px;
        bottom: 21px;
        right: 45px;
      }
    }

    .selectImage-center-right {
      width: 60%;
    }
    .selectImage-center-frame-two {
      height: 100%;
    }
    .selectImage-center-right-main {
      height: 335px;
      overflow-y: auto;
      background-color: #ffffff;
      border-radius: 4px;
      width: 505px;
    }

    .selectImage-center-right-main::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .selectImage-center-right-main::-webkit-scrollbar:vertical {
      width: 10px !important;
      // display: block !important;
    }

    .selectImage-center-right-main::-webkit-scrollbar-thumb {
      background-color: #003366;
      border-radius: 18px;
    }

    .selectImage-center-frame {
      padding: 10px;
      width: calc(100% / 3);  
    }

    .selectImage-center-frame-one {
      background-color: #003066;
      height: 100%;
    }

    .selectImage-center-frame-one input[type="file"] {
      display: none;
    }

    .selectImage-center-frame-text {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      width: 100px;
      height: 69px;
      text-align: center;
    }

    .selectImage-center-frame-border {
      padding: 10px;
      width: 100%;
      height: 100%;
    }
    .hover-main {
      width: 90%;
      height: 90%;
    }
    .hover-main:hover {
      border-radius: 5px;
      border: 1px solid #ffffff;
    }
  }
  .selectImage-Down {
    // width: 962px;
  }
}
@media only screen and (min-width: 768px) and(max-width: 1024px) {
  .selectImage-center-frame-one {
    height: 110px !important;
  }

  .selectImage .selectImage-center .selectImage-center-frame-text {
    color: #ffffff !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    width: 100px !important;
    text-align: center !important;
    height: 81px;
  }
  .imageTin {
    width: 250px;
    height: 200px;
  }
  .second-img {
    transform: rotateZ(1deg) rotateX(18deg) rotateY(-23deg) !important;
    position: absolute !important;
    width: 139px !important;
    height: 142px !important;
    bottom: 17px !important;
    right: 42px !important;
  }
}
