.personalize {
  width: 911px;
  height: 606px;
  .personalize-title {
    color: #1f366c;
    font-size: 32px;
    font-weight: 500;
    position: relative;
    margin-top: 25px;
    line-height: 1.313;
    top: 15px;
  }
  .personalize-center {
    height: 90%;
    .personalize-left {
      .pesonalize-action {
        width: 64px;
        height: 64px;
        border-radius: 10px;
        background-color: #ad9863;
      }
      #mainSvg {
        // width: 800px !important;
        height: 350px !important;
      }
      .pesonalize-action button {
        width: 64px;
        height: 64px;
        border-radius: 10px;
        background-color: #ad9863;
        border: none;
      }
      .personalize-left-up {
        .image-text {
          width: fit-content;
          padding: 5px;
          background: transparent;
          line-height: 0.8;
          font-weight: 300;
          padding: 5px;
          border: none;
          .image-text-button {
            position: absolute;
            display: inline-block;
            margin-left: 20px;
          }
          .image-text-button button {
            border: none;
            background-color: #202020;
            color: white;
            font-size: 22px;
            padding: 8px;
          }
        }
        .image-text:hover {
          border: 2px dashed #ffffff !important;
        }
      }
      .personalize-left-down {
        gap: 0px 10px;
      }
    }
    .personalize-right {
      padding: 35px;
      input[type="text"] {
        background-color: #ffffff;
        border-radius: 4px;
        width: 483px;
        height: 64px;
        font-size: 18px;
        color: #003366;
        border: none;
        border-style: none;
      }
      textarea:focus,
      input:focus,
      select:focus {
        outline: none;
      }
      ::-webkit-input-placeholder {
        color: #9f9f9f;
        font-size: 18px;
        letter-spacing: -0.5px;
        padding: 20px;
      }
      .select-menu {
        background-color: #ffffff;
        border-radius: 4px;
        width: 722px;
        // height: 115px;
        border: none;
        border-style: none;
        color: #003366;
        font-size: 18px !important;
        overflow-y: auto;
      }

      .ant-select-selector {
        height: 64px;
        // width: 483px;
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select-:not(.antd-select-customize-input)
        .ant-select-selector {
        border-color: none !important;
        box-shadow: none !important;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #fff;
        border: none !important;
        border-radius: 2px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single
        .ant-select-selector
        .ant-select-selection-placeholder {
        // padding: 34px 12px;
        transition: all 0.3s;
        font-size: 18px;
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none !important;
      }

      .add-text {
        background-color: #ad9863;
        padding: 0px !important;
      }
    }
  }
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  // right: 11px;
  // width: 50px;
  right: 6px;
  top: 51px;
  height: 18.91px;
  margin-top: -28px;
  color: #bfbfbf;
  font-size: 12px;
  line-height: 1;
  pointer-events: none;
}
.rc-virtual-list-holder {
  max-height: 265px !important;
}
.rc-virtual-list :second-child {
  height: 180 !important;
}
.rc-virtual-list-holder-inner {
  height: 230px !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ad9863 !important;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: #003366 !important;
}
.rc-virtual-list-scrollbar {
  width: 10px !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #003366 !important ;
}
.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
  font-size: 18px !important;
}

.ant-input {
  padding: 25px 11px !important;
}
.ant-select-item-option-content {
  color: #1f366c;
  width: 316px;
  // height: 66px;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.item-option-0 {
  background-color: #202020 !important;
  color: transparent;
  width: 200px;
  height: 25px;
}
.item-option-1 {
  width: 200px;
  height: 25px;
  border: 3px solid black !important;
  background-color: #ffffff !important;
  color: transparent;
}
.item-option-2 {
  width: 200px;
  height: 25px;
  background-color: #1F366C !important;
  color: transparent;
}
.item-option-3 {
  width: 200px;
  height: 25px;
  background-color: #EA5D5D !important;
  color: transparent;
}
.item-option-4 {
  width: 200px;
  height: 25px;
  background-color: #84AD63;
  color: transparent;
}
.item-option-5 {
  width: 200px;
  background-color: #6A0DAD;
  height: 25px;
  color: transparent;
}
.item-option-6 {
  width: 200px;
  height: 25px;
  background-color: #EAD151;
  color: transparent;
}
// .item-option-7 {
//   width: 200px;
//   height: 20px;
//   background-color: #ffe6cb;
//   color: transparent;
// }
::placeholder {
  font-size: 18px !important;
}
