.selectProduct-type {
  width: 911px;
  height: 577px;
}
.selectProdoct-type-center {
  // top: -45px;
  position: relative;
  // margin-top: 25px;
}
.selectProdoct-type-top {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
}
.selectProduct-type-top-text {
  font-size: 32px;
  color: #1f366c;
  font-weight: 500;
  text-align: center;
  width: 718px;
  height: 42px;
  margin-top: 40px;
}
.selectPrdocut-type-center-Left {
  width: 282px;
  height: 329px;
  border: 4px solid transparent;
  gap: 20px;
}
.selectPrdocut-type-center-right {
  width: 282px;
  height: 329px;
  border: 4px solid transparent;
  gap: 35px;
  justify-content: space-evenly !important;
}
.selectPrdocut-type-center-Left-text {
  color: #1f366c;
  font-size: 18px;
  font-weight: 500;
  width: 200px;
  text-align: center;
}
.selectPrdocut-type-center-Right-text {
  color: #1f366c;
  font-size: 18px;
  font-weight: 500;
  width: 200px;
  text-align: center;
  margin-bottom: 10px;
}
.refresh_api {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: none;
  background: #FFF;
}
// .selectPrdocut-type-center-right-text {
//   color: #1f366c;
//   font-size: 34px;
//   font-weight: 500;
//   width: 370px;
//   height: 81px;
//   text-align: center;
//   height: 0%;
// }
.selectProdoct-type-Down {
  margin-top: 40px;
}
// .selectPrdocut-type-center-Left:hover {
//   border: 4px solid #ad9863;
//   border-radius: 10px;
// }
// .selectPrdocut-type-center-right:hover {
//   border: 4px solid #ad9863;
//   border-radius: 10px;
// }

.selected-product {
  border: 4px solid #ad9863;
  border-radius: 10px;
}
.h-80 {
  height: 80%;
}
