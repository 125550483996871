.mainContent {
  min-height: auto;
 
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
}
.check-mark {
  align-items: center;
  display: flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 8px;
  background: #ad9863;
  border-radius: 50%;
}
.anticon svg {
  height: 20px;
  width: 20px;
}
