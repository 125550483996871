.crop-model {
  .ant-modal-content {
    //height: 1106px;
    //width: 1367px;
    width: 640px;
    height: 610px;
    top: 65px;
    border-radius: 10px;
    // max-width: 550px;
  }
  .ant-modal-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
  .crop {
    .crop-logo {
      display: flex;
      justify-content: end;
      padding: 20px;
      position: relative;
    }
    .crop-title {
      color: #1f366c;
      text-transform: uppercase;
      font-size: 32px;
      line-height: 1.313;
      font-weight: 500;
      text-align: center;
    }
    .button-cancle {
      background-color: #fafafa;
      color: #1f366c;
    }
    .cropSaveButton {
      width: auto !important;
    }
  }
}
.crop-main-modal {
  width: 100% !important;
  //margin-left: 32% !important;
  position: relative !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  top: 10px !important;
  bottom: 80px !important;
  overflow: initial !important;
}
.cropper-canvas {
  width: 100% !important;
  transform: translateX(0) !important;
}
.cropper-canvas img {
  width: 100% !important;
}
.cropper-container {
  width: 100% !important;
  height: 100% !important;
  margin-top: 45px;
}
.cropper-modal {
  background-color: #fff !important;
}
.ant-slider {
  margin-bottom: 30px;
  width: 335px !important;
}
.slider {
  display: flex;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.ant-slider-track {
  height: 13px !important;
  border-radius: 10px;
}
.ant-slider-step {
  height: 40px !important;
}
.ant-slider-handle {
  height: 25px !important;
  width: 25px !important;
  border-color: #f0f0ef !important;
}
.ant-slider-rail {
  height: 13px !important;
  border-radius: 10%;
}
//.progress {
//  background: linear-gradient(to right, #1F366C 0%, #1F366C 40%, #1F366C 40% );
//  border-radius: 8px;
//  height: 15px;
//  width: 450px;
//  outline: none;
//  overflow: visible;
//  -webkit-appearance: none;
//}
//.progress::-webkit-slider-thumb {
//  width: 40px;
//  height: 40px;
//  border-radius: 50%;
//  -webkit-appearance: none;
//  border :  2px solid #1F366C;
//  //background: white;
//  background-color:white;
//}

//input[type=range]::-ms-fill-lower {
//  background: #777;
//  border-radius: 10px;
//}
//input[type=range]::-ms-fill-upper {
//  background: #ddd;
//  border-radius: 10px;
//}
//input[type=range]::-ms-thumb {
//  border: none;
//  height: 16px;
//  width: 16px;
//  border-radius: 50%;
//  background: goldenrod;
//}
//input[type=range]:focus::-ms-fill-lower {
//  background: #888;
//}
//input[type=range]:focus::-ms-fill-upper {
//  background: #ccc;
//}
.crop-button {
  margin-top: 4rem !important;
}
.range_Button {
  flex: 0 0 auto;
  //width: 100px;
  height: 63px;
  border-radius: 100%;
  background: white;
  font-size: 60px;
  font-weight: 700;
  color: RGB(31, 54, 108);
  border: 0px !important;
  //cursor: pointer;
  -webkit-appearance: none;
  margin: 0 10px 50px 10px;
}
.range_Button-Minus {
  flex: 0 0 auto;
  //width: 100px;
  height: 63px;
  border-radius: 100%;
  background: white;
  font-size: 60px;
  font-weight: 700;
  color: RGB(31, 54, 108);
  border: 0px !important;
  //cursor: pointer;
  -webkit-appearance: none;
  margin: 0 15px 50px 10px;
}
