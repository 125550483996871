.delivery {
  width: 911px;
  height: 493px;
  .delivery-main {
    padding: 20px;
    input[type="text"] {
      background-color: #ffffff;
      border-radius: 4px;
      width: 170px;
      height: 64px;
      border: none;
      border-style: none;
      font-size: 18px;
      color: #1f366c;
      line-height: 0.5;
    }
    .lastname {
      width: 170px !important;
      height: 64px !important;
    }
    .email {
      width: 225px !important;
    }
    .phone {
      width: 221px !important;
    }
    .delivery-main-left {
      width: 400px;
    }
    textarea:focus,
    input:focus,
    select:focus {
      outline: none;
    }
    ::-webkit-input-placeholder {
      color: #9f9f9f;
      font-size: 18px !important;
      line-height: 1;
      padding-left: 20px;
    }
    .or {
      color: #545454;
      line-height: 0.643;
      font-size: 28px;
      font-weight: 500;
      width: 70px;
      justify-content: center;
      height: 65px;
    }
    input[type="checkbox"] {
      width: 36px;
      height: 36px;
      background-color: #ae9963;
    }
    input[type="checkbox"]:checked {
      background-color: #1f366c !important;
    }
    #one:checked:after {
      background-color: green;
    }
    .checkbox-lable {
      color: #1f366c;
      line-height: 1.222;
      font-size: 18px;
    }
    .checkbox-lable a {
      text-decoration: underline;
    }
  }
}
.delivery-main-margin {
  // margin-top: 130px !important;
}
.delivery-main-left-img {
  width: 320px;
}
.ant-checkbox-inner::after {
  position: absolute;
  display: table;
  top: 44%;
  left: 26.5%;
  display: table;
  width: 11.714286px;
  height: 19.142857px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: " ";
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ae9963 !important;
  border: none !important;
}
.ant-checkbox-inner {
  width: 35px !important;
  height: 35px !important ;
}
.personalGift-SubImage > #mainSvg {
  top: 51px;
  left: 26px;
  width: auto;
  position: relative;
  height: 80px !important;
  transform: rotate(3deg);
}
.personalGift-SubImage-tin > #mainSvg {
  top: 9px;
  left: 83px;
  width: auto;
  position: relative;
  height: 157px !important;
  transform: rotateZ(1deg) rotateX(16deg) rotateY(-23deg);
}
.personalGift-SubImage,
.personalGift-SubImage-tin {
  position: absolute;
}
.personalGift-SubImage-Bag-Image {
  height: 318px;
}
.personalGift-SubImage-Tin-Image {
  width: 291.69px;
  height: 210px;
}
.validation {
  color: red;
  font-size: 14px;
}
@media only screen and (min-width: 768px) and(max-width: 1024px) {
  .Terms-model .ant-modal-content {
    top: 50px;
  }
}
