.backgroundContent {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .backgroundImage {
    position: absolute;
    top: 0;
    right: 0;
    background-size: cover;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #ad986390;
  }
  .background-image {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
