.startBox {
  width: 768px;
  // margin: 40px 150px;
  height: 325px;
  // grid-gap: 17px;
  // gap: 17px;
  // max-width: 470px;

  .text {
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.333;
    width: 519px;
    height: 112px;
    font-size: 42px;
    color: #1f366c;
    font-weight: 500;
    text-transform: uppercase;
  }
  .english-button {
    padding: 15px 40px;
    font-size: 28px;
    color: #ffffff;
    text-transform: uppercase;
    width: 201px;
    height: 64px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .espanol-button {
    padding: 15px 40px;
    font-size: 28px;
    color: #ffffff;
    text-transform: uppercase;
    width: 211px;
    height: 64px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
