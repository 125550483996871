.warning-model {
  .ant-modal-content {
    height: 325px;
    width: 564px;
    top: 175px;
    border-radius: 10px;
  }
  //.ant-modal {
  //  width: 1100px !important;
  //  top: 73px;
  //}
  .ant-modal-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
}
.delivery-model-main {
  .delivery-model-title {
    color: #1f366c;
    font-size: 42px;
    font-weight: 500;
    text-align: center;
  }
  .delivery-model-details {
    width: 300px;
    // height: 84px;
    color: #333333;
    line-height: 1.333;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    margin-left: 28px;
  }
  .delivery-model-botton {
    margin-bottom: -50px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
