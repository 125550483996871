.temp {
  // padding: 25px 55px 40px 55px;
  width: 768px;
  height: 457px;
  .temp-logo {
    // width: 78.43px;
    // height: 78.43px;
    // background-color: #ba4e4e;
    // border-radius: 50px;
    position: relative;
    top: 15px;  
  }
  .temp-title {
    color: #1f366c;
    line-height: 1.429;
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 500;
  }
  .temp-details-main {
    width: 568px;
    height: 168px;
    .temp-details {
      color: #333333;
      line-height: 1.231;
      font-size: 26px;
    }
  }
}
