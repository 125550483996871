.ant-modal {
  // width: 1100px !important;
  transform-origin: 392.5px 417px;
  display: flex;
  justify-content: center;
}
.start-model {
  .ant-modal-content {
    height: 368px;
    width: 640px;
    border-radius: 10px;
    top: 175px;
    left: 0px;
  }
  .ant-progress-inner {
    width: 170px !important;
    height: 170px !important;
    font-size: 50px !important;
  }
  .ant-progress-text {
    color: #eee2c6 !important;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: #ba4e4e !important;
  }
  .ant-modal {
    width: 1100px !important;
    //top: 73px;
  }
  .ant-modal-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-progress-inner {
    background: #19336c !important;
  }
  .ant-modal-footer {
    display: none;
  }
  .modal-text {
    margin-top: 85px;
    color: #003366;
    line-height: 1.333;
    font-weight: 500;
    font-size: 42px;
    text-align: center;
    width: 295px;
  }
  .start-timer {
    top: -75px;
    position: absolute;
    left: 164px;
  }
}
.timeButton{
  width: auto !important;
}
.circular {
  height: 100px;
  width: 100px;
  position: relative;
  transform: scale(1.7);
  left: 110px;
  top: 15px;
}
.circular .inner {
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  margin: -40px 0 0 -40px;
  background: #1f366c;
  border-radius: 100%;
}
.circular .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 48px;
  font-weight: 500;
  color: #ede1c5;
}
.circular .bar {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  -webkit-border-radius: 100%;
  clip: rect(0px, 100px, 100px, 50px);
}
.circle .bar .progress {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 100%;
  clip: rect(0px, 50px, 100px, 0px);
  background: #ede1c5;
}
.circle .left .progress {
  z-index: 1;
  animation: left 15s linear both;
}
@keyframes left {
  100% {
    transform: rotate(180deg);
  }
}
.circle .right {
  transform: rotate(180deg);
  z-index: 3;
}
.circle .right .progress {
  animation: right 15s linear both;
  animation-delay: 15s;
}
@keyframes right {
  100% {
    transform: rotate(180deg);
  }
}
@media only screen and (min-width: 768px) and(max-width: 1024px) {
  .start-model .ant-modal-content {
    height: 350px;
    width: 58vw;
    border-radius: 10px;
    max-width: 1026px;
    top: 240px;
    left: 26px;
  }
  .start-timer {
    top: -73px !important;
    position: absolute !important;
    left: 174px !important;
  }
}
