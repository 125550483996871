.ant-modal {
  transform-origin: 392.5px 417px;
  display: flex;
  justify-content: center;
}
.Return-model {
  .ant-modal-content {
    width: 896px;
    height: 636px;
    border-radius: 10px;
  }

  .ant-modal-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
  .terms-logo {
    display: flex;
    justify-content: end;
    padding: 20px;
  }
  .terms-title {
    color: #1f366c;
    font-size: 32px;
    font-weight: 500;
    display: flex;
    line-height: 1.094;
    justify-content: center;
  }
  .return-details {
    color: #333333;
    font-size: 18px;
    line-height: 1.389;
    width: 90%;
    margin-left: 100px;
  }
  .terms-details::-webkit-scrollbar {
    width: 25px !important;
    display: block !important;
  }

  .terms-details::-webkit-scrollbar-thumb {
    background: #003366 !important;
    border-radius: 18px !important;
  }
}

.privecy-text {
  color: #333333;
  line-height: 1.222;
  font-size: 18px;
}
