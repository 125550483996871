.basic-info {
  width: 900px;
  height: 525px;
  .basic-info-sub {
    .basic-info-title {
      font-size: 32px;
      line-height: 1.313;
      color: #1f366c;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      width: 678px;
      height: 84px;
      margin-top: 50px;
    }
    .basic-info-center {
      margin-top: 20px;

      .basic-info-center-first {
        .pick-gift {
          width: calc(100% / 3);
          .pick-gift-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.444;
            text-transform: uppercase;
            color: #1f366c;
            width: 118px;
            text-align: center;
          }
          .background-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.444;
            text-transform: uppercase;
            color: #1f366c;
            text-align: center;
            width: 185px;
          }
          .customize-message-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.444;
            text-transform: uppercase;
            color: #1f366c;
            text-align: center;
            width: 214px;
          }
        }
      }
      .basic-info-center-second {
        margin-top: 50px;
        .pick-gift-second {
          // width: calc(100% / 2);
          .print-label-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.444;
            text-transform: uppercase;
            color: #1f366c;
            width: 144px;
            text-align: center;
          }
          .personalized-gift-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.444;
            text-transform: uppercase;
            color: #1f366c;
            width: 214px;
            text-align: center;
          }
        }
      }
    }
    .basic-info-button {
      margin-top: 30px !important;
      .basicInfo-button {
        width: 229px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
