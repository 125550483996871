.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 21px;
  position: absolute;
  bottom: 0;

  .footer-div {
    vertical-align: middle;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: #ffffff;
    bottom: 20px;
    position: relative;
    // margin-bottom: 100px !important;
  }
}
