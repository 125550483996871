.deleteText-model {
  .ant-modal-content {
    height: 325px;
    width: 564px;
    top: 185px;
    border-radius: 10px;
  }
  //.ant-modal {
  //  width: 1100px !important;
  //  top: 73px;
  //}
  .ant-modal-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
}

.deleteText-logo {
  position: absolute;
  top: -49px;
  left: 235px;
}
.deleteText-main {
  .deleteText-title {
    color: #003366;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.333;
    text-align: center;
  }
  .deleteText-details {
    width: 368px;
    // height: 48px;
    color: #333333;
    line-height: 1.333;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    // margin-left: 28px;
  }
  .deleteText-botton {
    margin-bottom: -50px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
